<template>
  <div class="ReportEntityDetails__Component">
    <FieldInlineText label="שם פרטי" @onChange="onChange" v-model="model.privateName" />

    <FieldInlineText label="שם משפחה" @onChange="onChange" v-model="model.lastName" />

    <FieldInlineText label="אימייל" @onChange="onChange" v-model="model.userName" />

    <FieldInlineText label="מספר טלפון" @onChange="onChange" v-model="model.phone1" />

    <FieldInlineText label="כתובת" @onChange="onChange" v-model="model.address" />

    <FieldInlineDateTime label="תאריך יצירה" @onChange="onChange" :readOnly="true" v-model="model.createdate" />

    <b-switch class="ltr" @input="onChange" v-model="model.status" :true-value="true" :false-value="false">
      {{ model.status ? "פעיל" : "לא פעיל" }}
    </b-switch>

    <FieldInlineSelect label="רמת הרשאה" :hideHover="true" @onChange="onChange" :filterable="false" :clearable="false" :options="[{ Value: 1, Text: 'אחר' },
    { Value: 2, Text: 'תלמיד' },
    { Value: 7, Text: 'מלווה' },]" v-model="model.userTypeId" />

    <b-switch class="ltr" @input="onChange" v-model="model.isAdminLinkAllowed" :true-value="true" :false-value="false">
      {{ model.isAdminLinkAllowed ? "פאנל ניהול" : "ללא גישה" }}
    </b-switch>

  </div>
</template>

<script>
import FieldInlineText from '@/components/Fields/FieldInlineText.vue';
import FieldInlineSelect from '@/components/Fields/FieldInlineSelect.vue';
import FieldInlineDateTime from '@/components/Fields/FieldInlineDateTime.vue';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'ReportEntityDetails',
  components: {
    FieldInlineText,
    FieldInlineSelect,
    FieldInlineDateTime,
  },
  props: ['readOnly'],
  data() {
    return {
      model: {
        id: null,
        privateName: null,
        lastName: null,
        userName: null,
        phone1: null,
        address: null,
        createdate: null,
        status: null,
        userTypeId: null,
        isAdminLinkAllowed: null
      },
    };
  },
  computed: {
    ...mapGetters('user', [
      'form',
    ]),
    changesAmount() {
      return Object.keys(this.diff(this.model, this.form)).length;
    },
  },
  created() {
    this.model = { ...this.model, ...this.form };
    // if (this.form.status === 1) {
    //   this.model.status = true;
    // } else this.model.status = false;
  },
  methods: {
    diff(o1, o2) {
      const diff = Object.keys(o2).reduce((diff1, key) => {
        if (o1[key] && typeof o1[key].getMonth === 'function') {
          if (new Date(o1[key]).toString() === new Date(o2[key]).toString()) return diff1;
        }
        if (typeof o1[key] === 'number') {
          if (parseInt(o1[key], 10) === parseInt(o2[key], 10)) return diff1;
        }
        if (o1[key] && typeof o1[key] === 'object' && typeof o1[key].getMonth !== 'function') {
          if (o1[key]?.Id === o2[key]?.Id) return diff1;
        }
        if (o1[key] === o2[key]) return diff1;
        return {
          ...diff1,
          [key]: o2[key],
        };
      }, {});

      return diff;
    },
    onChange() {
      this.$emit('onFormChange', this.changesAmount);
    },
    cancel() {
      this.model = { ...this.model, ...this.form };
    },
    save() {
      this.$emit('onFormSave', {
        model: this.model,
        cancel: this.cancel,
        setRestorePoint: this.setRestorePoint,
      });
    },
    setRestorePoint() {
      this.storeReportDetails(this.model);
    },
    refresh() {
      this.model = { ...this.model, ...this.form };
    },
    ...mapMutations('user', [
      'storeReportDetails',
    ]),
  },
};
</script>

<style></style>
