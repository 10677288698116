var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ReportEntityDetails__Component" },
    [
      _c("FieldInlineText", {
        attrs: { label: "שם פרטי" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.privateName,
          callback: function($$v) {
            _vm.$set(_vm.model, "privateName", $$v)
          },
          expression: "model.privateName"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "שם משפחה" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.lastName,
          callback: function($$v) {
            _vm.$set(_vm.model, "lastName", $$v)
          },
          expression: "model.lastName"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "אימייל" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.userName,
          callback: function($$v) {
            _vm.$set(_vm.model, "userName", $$v)
          },
          expression: "model.userName"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "מספר טלפון" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.phone1,
          callback: function($$v) {
            _vm.$set(_vm.model, "phone1", $$v)
          },
          expression: "model.phone1"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "כתובת" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.address,
          callback: function($$v) {
            _vm.$set(_vm.model, "address", $$v)
          },
          expression: "model.address"
        }
      }),
      _c("FieldInlineDateTime", {
        attrs: { label: "תאריך יצירה", readOnly: true },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.createdate,
          callback: function($$v) {
            _vm.$set(_vm.model, "createdate", $$v)
          },
          expression: "model.createdate"
        }
      }),
      _c(
        "b-switch",
        {
          staticClass: "ltr",
          attrs: { "true-value": true, "false-value": false },
          on: { input: _vm.onChange },
          model: {
            value: _vm.model.status,
            callback: function($$v) {
              _vm.$set(_vm.model, "status", $$v)
            },
            expression: "model.status"
          }
        },
        [_vm._v(" " + _vm._s(_vm.model.status ? "פעיל" : "לא פעיל") + " ")]
      ),
      _c("FieldInlineSelect", {
        attrs: {
          label: "רמת הרשאה",
          hideHover: true,
          filterable: false,
          clearable: false,
          options: [
            { Value: 1, Text: "אחר" },
            { Value: 2, Text: "תלמיד" },
            { Value: 7, Text: "מלווה" }
          ]
        },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.userTypeId,
          callback: function($$v) {
            _vm.$set(_vm.model, "userTypeId", $$v)
          },
          expression: "model.userTypeId"
        }
      }),
      _c(
        "b-switch",
        {
          staticClass: "ltr",
          attrs: { "true-value": true, "false-value": false },
          on: { input: _vm.onChange },
          model: {
            value: _vm.model.isAdminLinkAllowed,
            callback: function($$v) {
              _vm.$set(_vm.model, "isAdminLinkAllowed", $$v)
            },
            expression: "model.isAdminLinkAllowed"
          }
        },
        [
          _vm._v(
            " " +
              _vm._s(_vm.model.isAdminLinkAllowed ? "פאנל ניהול" : "ללא גישה") +
              " "
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }