<template>
  <div class="Report__Entity">
    <Entity ref="entity" :entity-type="'user'" :entity-id="userId" :innerComponent="innerComponent"
      innerComponentTitle="פרטי איש קשר" title="איש קשר" back-button-text="רשימת משתמשים"
      :back-button-url="!isCourse ? 'users' : 'admin/users'" :widgets="widgets" :actions="actions" :preview="preview"
      :read-only="readOnly" :entityReady="entityReady" :activities="[]" @onFormSave="onFormSave"
      @onFormDiscard="onFormDiscard">
      <template #mandatory>
        <div class="Accident__Entity__Details__BaseDetails__Stage">
          <span class="ltr">הפניה לסימולטור:</span>
          <span @click="openSimulator" class="
              Accident__Entity__Details__BaseDetails__Stage__Value
              value
              has-text-weight-bold
              link
            ">קישור</span>
        </div>
      </template>
    </Entity>
  </div>
</template>

<script>
import Entity from "@/components/Entity.vue";
import UserEntityDetails from "@/components/UserEntityDetails.vue";
import moment from "moment";
import { mapActions } from "vuex";
import { createHelpers } from "vuex-map-fields";
import UserService from "@/services/UserService";
// import VehicleService from "@/services/VehicleService";
import ArchiveService from "@/services/ArchiveService";
import DialogReportHistory from "@/components/DialogReportHistory.vue";
import DialogUpdateReportAdmin from "@/components/DialogUpdateReportAdmin.vue";
import {
  DialogProgrammatic as Dialog,
  ToastProgrammatic as Toast,
  ModalProgrammatic as Modal
} from "buefy";
// import store from '../store';
import WidgetService from "../services/WidgetService";
// import FieldDateHourInline from "../components/Fields/FieldDateHourInline.vue";
// import DriverService from "../services/DriverService";

const { mapFields } = createHelpers({
  getterType: "user/getField",
  mutationType: "user/updateField",
});

export default {
  components: {
    Entity,
    // FieldDateHourInline,
  },
  props: ["id", "preview", "readOnly"],
  computed: {
    userId() {
      return this.$route.params.id || this.id;
    },
    isAdminRoute() {
      return this.$route.name.includes('Admin');
    },
    isCourse() {
      return !this.$store.state.auth.course?.id;
    },
    widgets() {
      return [
        {
          ...WidgetService.StudentGroups,
          namespace: "user",
          multiple: true
        },
        {
          ...WidgetService.TrackStudents,
          namespace: "user",
          multiple: true
        },
        {
          ...WidgetService.LecturesStudents,
          namespace: "user",
          multiple: true
        },
        {
          ...WidgetService.StudentCourses,
          namespace: "user",
          multiple: true
        },
        {
          ...WidgetService.StudentExercises,
          namespace: "user",
          multiple: true,
          isHidden: this.isAdminRoute
        }
        // {
        //   ...WidgetService.ReportActivities, namespace: "report",
        // },
      ];
    },
    actions() {
      return [
        {
          text: "אימות דוח",
          onClick: () => {
            Dialog.confirm({
              message: "האם לבצע אימות עבור דוח זה?",
              onConfirm: () => {
                UserService.scanDetailsFromMunic({
                  reportNumber: this.ReportNumber,
                });
                Toast.open({
                  type: "is-success",
                  message:
                    "הסריקה החלה... התהליך עשוי להמשך מספר דקות. ניתן להמשיך בעבודה",
                  duration: 10000,
                });
              },
              confirmText: "בטוח!",
              cancelText: "בטל",
            });
          },
        },
        {
          text: "היסטוריית אירועים",
          onClick: () => {
            ArchiveService.getHistory(this.userId).then((r) => {
              this.data = r.data;

              this.$buefy.modal.open({
                component: DialogReportHistory,
                props: {
                  data: this.data,
                },
                canCancel: false,
                hasModalCard: true,
                trapFocus: true,
              });
            });
          },
        },
        {
          text: 'עריכת פרטי דוח',
          onClick: () => {
            Modal.open({
              component: DialogUpdateReportAdmin,
              customClass: "entity-modal",
              props: {
                // driverId: this.driver.Id,
                onSuccess: this.init,
                report: { ...this.$store.state.report }
              },
              canCancel: false,
              trapFocus: true,
            });
          }
        }
      ];
    },
    innerComponent() {
      return UserEntityDetails;
    },
    ...mapFields(["privateName", "lastName", "userName", "phone1", "address", "createDate", "status"]),
  },
  filters: {
    date(value) {
      const date = moment(value, "YYYY-MM-DDThh:mm");
      return date.format("DD/MM/YYYY hh:mm");
    },
  },
  data() {
    return {
      entityReady: false,
      isEntityPage: false
    };
  },
  async created() {
    this.init();
    this.isEntityPage = !this.$route.params.id;
  },
  methods: {
    async init(callback) {
      try {
        await this.load({
          id: this.userId,
        });
        this.entityReady = true;
        if (callback) {
          callback();
        }
      } catch (err) {
        console.log(err);
      }
    },
    openSimulator() {
      window.open(`${process.env.VUE_APP_URL}?StudentId=${this.id}`, '_blank');
    },
    onFormSave({ refresh, closeDialog, model, saving }) {
      saving(true);
      const newModel = { ...model };
      UserService.updateUser({
        ...this.$store.state.user,
        ...newModel,
      })
        .then(() => {
          this.init(refresh);
          this.$emit('onSave');
          this.$buefy.toast.open({
            type: "is-success",
            message: "נשמר בהצלחה!",
            duration: 4000,
          });
        })
        .catch(() => {
          this.$buefy.toast.open({
            type: "is-danger",
            message: "קרתה תקלה, השמירה לא התבצעה!",
            duration: 8000,
          });
          refresh();
        })
        .finally(() => {
          saving(false);
          closeDialog();
        });
    },
    onFormDiscard({ restoreState, closeDialog }) {
      restoreState();
      closeDialog();
    },
    ...mapActions({
      load: "user/loadUser",
    }),
  },
  // watch: {
  //   id(old, newValue) {
  //     console.log(old, newValue);
  //   },
  // },
};
</script>

<style scoped lang="scss">
.link {
  text-decoration: underline;
  cursor: pointer;
}

.flex-between {
  display: flex;
  justify-content: space-between;

  .Accident__Entity__Details__BaseDetails__Stage__Value {
    padding-right: 0;
  }

  ::v-deep .b-tooltip {
    margin-left: 10px;
  }

  .Pay__Icon {
    border-radius: 3px;
    color: #0091ae;
    position: relative;
    right: -5px;
    font-size: 14px;

    .payment-btn {
      color: white;
      position: absolute;
      left: 20px;
      background-color: #ff7a59;
      height: 45px;
      margin: 0 10px;
      border: none;
      padding: 10px;
      cursor: pointer;

      &:hover {
        background-color: #fb6743;
      }
    }

    i {
      width: 30px;
      display: inline-block;
      vertical-align: middle;
    }
  }
}
</style>
